import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-base" };
const _hoisted_2 = { class: "relative" };
const _hoisted_3 = { class: "font-bold" };
const _hoisted_4 = { class: "list-disc ml-4 text-xs" };
const _hoisted_5 = { class: "uppercase" };
const _hoisted_6 = { class: "capitalize" };
const _hoisted_7 = { class: "mt-6 flex flex-col gap-4" };
const _hoisted_8 = { class: "font-semibold" };
import { BridgingNetwork } from "@injectivelabs/sdk-ui-ts";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketDeprecated",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const router = useRouter();
    const isModalOpen = computed(() => modalStore.modals[Modal.MarketDeprecated]);
    const ticker = computed(() => props.market.ticker);
    const symbol = computed(() => props.market.baseToken.symbol);
    const network = computed(() => {
      if (props.market.slug === "huahua-usdt") {
        return BridgingNetwork.Chihuahua;
      }
      return BridgingNetwork.Injective;
    });
    function close() {
      modalStore.closeModal(Modal.MarketDeprecated);
      router.push({ name: "index" });
    }
    return (_ctx, _cache) => {
      const _component_i18n_t = _resolveComponent("i18n-t");
      const _component_AppButton = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppModal = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("marketDeprecated.title")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_i18n_t, {
              keypath: "marketDeprecated.description",
              tag: "p",
              class: "text-sm mb-3"
            }, {
              ticker: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(ticker)), 1)
              ]),
              _: 1
            }),
            _createElementVNode("ul", _hoisted_4, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t("marketDeprecated.subDescriptionOne", { ticker: _unref(ticker) })), 1),
              _createVNode(_component_i18n_t, {
                keypath: "marketDeprecated.subDescriptionTwo",
                tag: "li",
                class: "mt-2"
              }, {
                symbol: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(symbol)), 1)
                ]),
                network: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(network)), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_NuxtLink, {
                to: { name: "markets" },
                class: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    lg: "",
                    class: "w-full text-blue-900 bg-blue-500"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("marketDeprecated.exploreOtherMarkets")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
