import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Derivatives/Trading/Form.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Modals/MarketNew.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Modals/MarketDeprecated.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Layout.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
import { Modal } from "@/types";
import { deprecatedMarkets, upcomingMarkets } from "@/app/data/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "[market]",
  setup(__props) {
    const modalStore = useModalStore();
    const route = useRoute();
    const routeParamMarket = route.params.market;
    const marketIsNew = upcomingMarkets.some(
      ({ slug }) => slug === routeParamMarket
    );
    const deprecatedMarket = deprecatedMarkets.find(
      (m) => m.slug === routeParamMarket
    );
    const market = ref(void 0);
    function onLoad(pageMarket) {
      market.value = pageMarket;
      if (marketIsNew) {
        modalStore.openModal({ type: Modal.MarketNew });
      } else if (deprecatedMarket) {
        modalStore.openModal({ type: Modal.MarketDeprecated });
      }
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingDerivativesTradingForm = __nuxt_component_0;
      const _component_PartialsTradingDerivativeOrders = _resolveComponent("PartialsTradingDerivativeOrders");
      const _component_ModalsMarketNew = __nuxt_component_1;
      const _component_ModalsMarketDeprecated = __nuxt_component_2;
      const _component_PartialsTradingLayout = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_PartialsTradingLayout, {
        "hardcoded-slug": "btc-usdt-perp",
        onLoaded: onLoad
      }, {
        "trading-form": _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingDerivativesTradingForm, {
            key: 0,
            market: _unref(market)
          }, null, 8, ["market"])) : _createCommentVNode("", true)
        ]),
        orders: _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingDerivativeOrders, {
            key: 0,
            market: _unref(market)
          }, null, 8, ["market"])) : _createCommentVNode("", true)
        ]),
        modals: _withCtx(() => [
          _createElementVNode("div", null, [
            _unref(marketIsNew) ? (_openBlock(), _createBlock(_component_ModalsMarketNew, { key: 0 })) : _createCommentVNode("", true),
            _unref(deprecatedMarket) ? (_openBlock(), _createBlock(_component_ModalsMarketDeprecated, {
              key: 1,
              market: _unref(deprecatedMarket)
            }, null, 8, ["market"])) : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      });
    };
  }
});
