import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-base" };
const _hoisted_2 = { class: "mt-6 flex items-center justify-center" };
const _hoisted_3 = { class: "flex items-center justify-center" };
const _hoisted_4 = { class: "mr-2 font-semibold" };
import { upcomingMarkets } from "@/app/data/market";
import { getHubUrl } from "@/app/utils/helpers";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketNew",
  setup(__props) {
    const modalStore = useModalStore();
    const router = useRouter();
    const [upcomingMarket] = upcomingMarkets;
    const baseTokenSymbol = upcomingMarket.baseToken.symbol;
    const quoteTokenSymbol = upcomingMarket.quoteToken.symbol;
    const bridgeUrl = computed(
      () => `${getHubUrl()}/bridge/?token=${baseTokenSymbol}`
    );
    const isModalOpen = computed(() => modalStore.modals[Modal.MarketNew]);
    function close() {
      modalStore.closeModal(Modal.MarketNew);
      router.push({ name: "index" });
    }
    return (_ctx, _cache) => {
      const _component_i18n_t = _resolveComponent("i18n-t");
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_AppModal = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("marketNew.title")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_i18n_t, {
              keypath: "marketNew.description",
              tag: "p",
              class: "text-center text-sm text-white"
            }, {
              baseSymbol: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_unref(baseTokenSymbol)), 1)
              ]),
              quoteSymbol: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_unref(quoteTokenSymbol)), 1)
              ]),
              upcomingMarketSymbol: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_unref(baseTokenSymbol)), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_NuxtLink, {
                to: _unref(bridgeUrl),
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    lg: "",
                    class: "text-blue-900 bg-blue-500"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("marketNew.depositNow")), 1),
                        _createVNode(_component_BaseIcon, {
                          name: "external-link",
                          class: "w-3 h-3"
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
